import React from "react"
import { graphql } from 'gatsby'
import Slider from "react-slick";
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


class SimpleSlider extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      fade: true,
      autoplay: true,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <Slider {...settings}>
      {this.props.images.map((image, key) => (
        <div key={key}>
          <Img fluid={image.localFile.childImageSharp.fluid} />
        </div>
      ))}
      </Slider>
    )
  }
}

const PageTemplate = ({ data }) => {
  return (
  <Layout>
    <SEO
      title={data.wordpressPage.title}
      description={data.wordpressPage.excerpt}
    />
    <div className="content">
      <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.content }} />
    </div>
    <div className="gallery">
      {data.allWordpressPage.edges.map((post, index) =>(
        <SimpleSlider key={index} images={post.node.acf.gallery} />
      ))}
    </div>
  </Layout>
)}
export default PageTemplate

export const query = graphql`
  query($id: Int!) {
    allWordpressPage (filter: {wordpress_id: {eq: $id}}){
      edges {
        node {
          acf {
            content
            gallery {
              localFile {
                childImageSharp {
                  # Try editing the "maxWidth" value to generate resized images.
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      excerpt
      acf {
        content
        gallery {
                localFile {
                  childImageSharp {
                    fluid {
                      srcWebp
                      srcSetWebp
                      src
                    }
                }
              }
            }
      }
    }
  }
`
